@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Buda';
  src: local('Buda'), url(fonts/Buda.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

@font-face {
  font-family: 'DancingScript';
  src: local('DancingScript'), url(fonts/DancingScript.woff) format('woff');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

.cursor-none {
  cursor: none !important;
}
.font-buda {
  font-family: 'Buda';
}
.font-dancing {
  font-family: 'DancingScript';
}

.complete-svg {
  stroke-dasharray: 2000;
  animation: dash-complete 20s linear forwards;
}

.draw-svg {
  stroke-dasharray: 1000;
  stroke-dashoffset: 1000;
  animation: dash 30s linear forwards;
}

@keyframes dash {
  to {
    stroke-dashoffset: 0;
  }
}

@keyframes dash-complete {
  to {
    stroke-dasharray: 0;
  }
}


@keyframes shake {
  0% { transform: translate(1px, 1px) rotate(0deg); }
  10% { transform: translate(-1px, -2px) rotate(-1deg); }
  20% { transform: translate(-3px, 0px) rotate(1deg); }
  30% { transform: translate(3px, 2px) rotate(0deg); }
  40% { transform: translate(1px, -1px) rotate(1deg); }
  50% { transform: translate(-1px, 2px) rotate(-1deg); }
  60% { transform: translate(-3px, 1px) rotate(0deg); }
  70% { transform: translate(3px, 1px) rotate(-1deg); }
  80% { transform: translate(-1px, -1px) rotate(1deg); }
  90% { transform: translate(1px, 2px) rotate(0deg); }
  100% { transform: translate(1px, -2px) rotate(-1deg); }
}
.animate-shake {
  animation: shake 0.5s;
  animation-iteration-count: infinite;
}

.fractal {
  object-fit: cover;
  background-image: url(gifs/fractal_1.gif);
  background-size: cover;
  background-position: center center;
}


.toe {
  position: fixed;
  top:0;
  left: 0;
  cursor: none !important;

  /* transform: translate(-50%, -50%);
  -webkit-transition-duration: 100ms;
  transition-duration: 100ms;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  will-change: width, height, transform, border; */
z-index: 999;
  pointer-events: none;
  
}
.toe img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
@keyframes float {
	0% {
		transform: translatey(0px);
	}
	50% {
		transform: translatey(-10px);
	}
	100% {
		transform: translatey(0px);
	}
}

@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}




@keyframes llama {
  0%   { transform: scale(1,1)      translateY(0); }
  10%  { transform: scale(1.0,.9)   translateY(0); }
  30%  { transform: scale(.9,0.9)   translateY(-60px); }
  50%  { transform: scale(1.0,0.9) translateY(0); }
  57%  { transform: scale(1,1)      translateY(-7px); }
  64%  { transform: scale(1,1)      translateY(0); }
  100% { transform: scale(1,1)      translateY(0); }
}

.animate-llama {
  animation-name: llama;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: cubic-bezier(0.280, 0.840, 0.420, 1);
}


.animate-float {
  animation: float 2s ease-in-out infinite;
}


.bg-black-to-white {
  background: radial-gradient(#e66465, #9198e5);
}

.fractal-flower {
    width: 800px;
    height: 800px;
    background-image: url(gifs/fractal_2.gif);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  
    mask-image: url(svgs/flower.svg);
    mask-size: 800px;
    mask-repeat: no-repeat;
    mask-position: center;
  }